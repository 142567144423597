






























































// Vue
import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Models
import { IMaterial } from "@/core/_models/material.model";
// Services
import AdminService from "@/views/admin/Admin.service";

export default Vue.extend({
  name: "CreateMaterialDialog",
  props: {
    material: Object as PropType<IMaterial>
  },

  data() {
    return {
      dialog: false,

      name: undefined as string | undefined,
      index: undefined as number | undefined
    };
  },

  mixins: [validationMixin],

  validations: {
    name: { required }
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    init(): void {
      if (this.material) {
        this.name = this.material.Name;
        this.index = this.material.Index;
      }
    },

    submit(): void {
      if (!this.name) return;

      const payload: IMaterial = {
        DatabaseId: this.material.DatabaseId,
        Name: this.name,
        Index: this.index!
      };

      // * Submit to server
      AdminService.updateMaterial(this.currentUser.clientId, payload)
        .then(() => {
          this.$emit("refresh-materials");
          this.close();
          this.ADD_SUCCESS_MESSAGE("Material successfully updated.");
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE("Error updating material. Please try again.");
        });

      this.close();
    },

    close(): void {
      this.name = undefined;

      this.$v.$reset();
      this.dialog = false;
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    nameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    }
  }
});
