





















































































































// Vue
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
// vuedraggable
import draggable from "vuedraggable";
// Models
import { IMaterial } from "@/core/_models/material.model";
// Services
import AdminService from "@/views/admin/Admin.service";
// Components
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";
import CreateMaterialDialog from "@/views/admin/_dialogs/materials/CreateMaterialDialog.vue";
import UpdateMaterialDialog from "@/views/admin/_dialogs/materials/UpdateMaterialDialog.vue";

export default Vue.extend({
  name: "Materials",
  components: { draggable, CreateMaterialDialog, ConfirmationDialog, UpdateMaterialDialog },

  data() {
    return {
      isLoading: false,
      isSearching: false,

      search: "",

      materialsData: [] as IMaterial[]
    };
  },

  beforeMount() {
    this.getAllMaterials();
  },

  // Update the indexes of all Materials on Destroy
  beforeDestroy() {
    this.saveLayout();
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    saveLayout(): void {
      this.isLoading = true;

      let index = 0;
      this.materialsData.forEach((element: IMaterial) => {
        element.Index = index;
        index++;

        AdminService.updateMaterial(this.currentUser.clientId, element).then(() => {
          this.isLoading = false;
        });
      });
    },

    getAllMaterials(): void {
      this.isLoading = true;

      AdminService.getAllMaterials(this.currentUser.clientId).then((data: IMaterial[]) => {
        if (!data) return;
        this.materialsData = data;
        this.isLoading = false;
      });
    },

    resetSearch(): void {
      this.search = "";
      this.isSearching = false;
    },

    deleteMaterial(elementToDelete: IMaterial): void {
      AdminService.deleteMaterial(this.currentUser.clientId, elementToDelete.DatabaseId!)
        .then(() => {
          this.ADD_SUCCESS_MESSAGE("Material successfully deleted.");
          this.getAllMaterials();
          this.resetSearch();
        })
        .catch((error) => {
          console.log(error);
          this.ADD_SUCCESS_MESSAGE("Error deleting material. Please try again.");
        });
    }
  },

  computed: {
    ...mapGetters({
      items: "materials/items",
      currentUser: "currentUser"
    }),

    /**
     * Filters Materials via search bar
     * @author Nick Brahimir
     */
    filteredMaterials(): any {
      return this.materialsData.filter((element: IMaterial): any => {
        return element.Name?.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    /**
     * Animations for Drag n Drop (see docs below)
     * https://www.npmjs.com/package/vuedraggable
     * https://sortablejs.github.io/Vue.Draggable/#/transition-example-2
     * https://github.com/SortableJS/Vue.Draggable/blob/master/example/components/transition-example-2.vue
     * @author Nick Brahimir
     */
    dragOptions(): any {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost" // Corresponds to the scoped scss class.
      };
    },

    dragOff(): any {
      return {
        disabled: true
      };
    }
  }
});
